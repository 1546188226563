import { gql } from "@apollo/client";

// ====== DRAFT ======

export const CREATE_DRAFT = gql`
  mutation createDraft($input: CreateDraftLibraryInput!) {
    createDraft(input: $input) {
      id
      title
      organizationId
      status
      tabs {
        id
        name
        order
        checklistId
        draftId
        sections {
          id
          name
          description
          color
          order
          checkbox
          collapsable
          startCollapsable
          dropdownInputs
          checklistTabId
          items {
            id
            description
            type
            data
            checkbox
            collapsable
            order
            startCollapsable
            tabSectionId
            dropdownInputs
          }
        }
      }
    }
  }
`;

export const CREATE_DRAFT_LIBRARY = gql`
  mutation createDraftLibrary($input: CreateDraftLibraryInput!) {
    createDraftLibrary(input: $input) {
      id
    }
  }
`;

export const UPDATE_DRAFT = gql`
  mutation updateDraft($input: UpdateDraftInput!) {
    updateDraft(input: $input) {
      id
    }
  }
`;

export const PUBLISH_DRAFT = gql`
  mutation publishDraft($id: Int!) {
    publishDraft(id: $id)
  }
`;

export const REMOVE_DRAFT = gql`
  mutation removeDraft($id: Int!) {
    removeDraft(id: $id) {
      id
    }
  }
`;

// ====== TAB ======

export const CREATE_TAB = gql`
  mutation createChecklistTab($input: CreateChecklistTabInput!) {
    createChecklistTab(input: $input) {
      id
      name
      order
      checklistId
      draftId
      sections {
        id
        name
        description
        color
        order
        checkbox
        collapsable
        startCollapsable
        dropdownInputs
        checklistTabId
        items {
          id
          description
          type
          data
          checkbox
          collapsable
          order
          startCollapsable
          tabSectionId
          dropdownInputs
        }
      }
    }
  }
`;

export const DUPLICATE_TAB = gql`
  mutation duplicateChecklistTab($id: Int!) {
    duplicateChecklistTab(id: $id) {
      id
      name
      order
      checklistId
      draftId
      sections {
        id
        name
        description
        color
        order
        checkbox
        collapsable
        startCollapsable
        dropdownInputs
        checklistTabId
        items {
          id
          description
          type
          data
          checkbox
          collapsable
          order
          startCollapsable
          tabSectionId
          dropdownInputs
        }
      }
    }
  }
`;

export const UPDATE_TAB = gql`
  mutation updateChecklistTab($input: UpdateChecklistTabInput!) {
    updateChecklistTab(input: $input) {
      id
    }
  }
`;

export const CHANGE_TAB_ORDERS = gql`
  mutation changeChecklistTabOrders($input: ChangeChecklistTabOrdersInput!) {
    changeChecklistTabOrders(input: $input)
  }
`;

export const REMOVE_TAB = gql`
  mutation removeChecklistTab($id: Int!) {
    removeChecklistTab(id: $id)
  }
`;

// ====== SECTION ======

export const CREATE_SECTION = gql`
  mutation createTabSection($input: CreateTabSectionInput!) {
    createTabSection(input: $input) {
      id
      name
      description
      color
      order
      checkbox
      collapsable
      startCollapsable
      dropdownInputs
      checklistTabId
      items {
        id
        description
        type
        data
        checkbox
        collapsable
        order
        startCollapsable
        tabSectionId
        dropdownInputs
      }
    }
  }
`;

export const UPDATE_SECTION = gql`
  mutation updateTabSection($input: UpdateTabSectionInput!) {
    updateTabSection(input: $input) {
      id
    }
  }
`;

export const CHANGE_SECTION_ORDERS = gql`
  mutation changeTabSectionOrders($input: ChangeTabSectionOrdersInput!) {
    changeTabSectionOrders(input: $input)
  }
`;

export const REMOVE_SECTION = gql`
  mutation removeTabSection($id: Int!) {
    removeTabSection(id: $id)
  }
`;

export const DUPLICATE_SECTION = gql`
  mutation duplicateTabSection($id: Int!) {
    duplicateTabSection(id: $id) {
      id
      name
      description
      color
      checkbox
      collapsable
      order
      startCollapsable
      dropdownInputs
      checklistTabId
      items {
        id
        description
        type
        data
        checkbox
        collapsable
        order
        startCollapsable
        tabSectionId
        dropdownInputs
      }
    }
  }
`;

// ====== ITEM ======

export const CREATE_ITEM = gql`
  mutation createSectionItem($input: CreateSectionItemInput!) {
    createSectionItem(input: $input) {
      id
      type
      checkbox
      data
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation updateSectionItem($input: UpdateSectionItemInput!) {
    updateSectionItem(input: $input) {
      id
    }
  }
`;

export const CHANGE_ITEM_ORDERS = gql`
  mutation changeSectionItemOrders($input: ChangeSectionItemOrdersInput!) {
    changeSectionItemOrders(input: $input)
  }
`;

export const REMOVE_ITEM = gql`
  mutation removeSectionItem($id: Int!) {
    removeSectionItem(id: $id) {
      id
    }
  }
`;

export const DUPLICATE_ITEM = gql`
  mutation duplicateSectionItem($id: Int!) {
    duplicateSectionItem(id: $id) {
      id
      description
      type
      data
      checkbox
      collapsable
      order
      startCollapsable
      tabSectionId
      dropdownInputs
    }
  }
`;

// export const CREATE_CHECKEM_DRAFT = gql`
//   mutation createDraftLibrary {
//     createDraftLibrary {
//       id
//     }
//   }
// `;

export const IMPORT_CHECKEM_DRAFT = gql`
  mutation importChecklist($input: ImportChecklistInput!) {
    importChecklist(input: $input) {
      id
    }
  }
`;

export const DUPLICATE_DRAFT = gql`
  mutation duplicateDraft($id: Int!) {
    duplicateDraft(id: $id) {
      id
    }
  }
`;

export const MOVE_DRAFT_TO_FOLDER = gql`
  mutation addDraftToFolder($input: AddDraftToFolderInput!) {
    addDraftToFolder(input: $input) {
      id
    }
  }
`;

export const REMOVE_DRAFT_FROM_FOLDER = gql`
  mutation removeDraftFromFolder($id: Int!) {
    removeDraftFromFolder(id: $id)
  }
`;

export const UNPUBLISH_DRAFT = gql`
  mutation unpublishDaft($id: Int!) {
    unpublishDraft(id: $id)
  }
`
