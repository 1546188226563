import { ChecklistItemType, IItem, ISection } from "~models";
import styles from "./styles.module.scss";
import { Checkbox } from "~components";
import {
  Algorithm,
  BurnedAreas,
  Calculator,
  Counter,
  DropdownData,
  InputText,
  MedCheck,
  Metronome,
  Selector,
  Stopwatch,
  TextBox,
  TimeMarker,
  Timer,
} from "./modules";
import clsx from "clsx";
import { useItem } from "./useItem";
import { DownArrow } from "~icons";
import dayjs from "dayjs";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import FroalaEditor from "react-froala-wysiwyg";
interface IProps {
  item: IItem;
  itemIndex: number;
}
export const Item = ({ item, itemIndex }: IProps) => {
  const {
    progressItem,
    parsedItem,
    isDisable,
    toggleCheckbox,
    toggleCollapsed,
  } = useItem({
    item,
    itemIndex
  });
  const isDark = useReactiveVar(darkTheme);

  const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = e.target as HTMLElement;

    const closestAnchor = targetElement.closest('a');

    if (closestAnchor) {
      const href = closestAnchor.getAttribute('href');

      if (href) {
        window.open(href, '_blank');
      }

      return;
    }

    if (!progressItem?.startCollapsable && progressItem?.checkbox) {
      e.stopPropagation();
      toggleCheckbox();
    }
  };

  return (
    <div
      className={
        isDark
          ? clsx(styles.item, styles.itemDark, {
              [styles.firstItem]: itemIndex === 0,
              [styles.checked]: progressItem?.isChecked,
            })
          : clsx(styles.item, {
              [styles.firstItem]: itemIndex === 0,
              [styles.checked]: progressItem?.isChecked,
            })
      }
      onClick={handleItemClick}
    >
      <div
        className={clsx(styles.item__header, {
          [styles.item__headerChecked]: progressItem?.isChecked,
        })}
        style={
          item.type === ChecklistItemType.Checkbox ? { border: "none" } : {}
        }
      >
        <div
          className={clsx(styles.item__headerLeft, {
            [styles.item__headerLeftChecked]: progressItem?.isChecked,
          })}
        >
          {progressItem?.collapsable && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleCollapsed();
              }}
              className={clsx(styles.item__collapseArrow, {
                [styles.rotate]: !progressItem?.startCollapsable,
              })}
            >
              <DownArrow fill="#0B0F1C" />
            </div>
          )}
          {item.description ? (
            <FroalaEditor
              model={item.description
                ?.replace(/^(<br>)+|(<br>)+$/g, "")
                .replace(
                  /<p>\s*<br>\s*<\/p>|<p>\s*<\/p>|<p><strong><em><\/em><\/strong><br><\/p>|<p><strong><em><br><\/em><\/strong><\/p>/g,
                  ""
                )}
              config={{
                key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                htmlUntouched: true,
                toolbarInline: true,
                toolbarVisibleWithoutSelection: true,
                isDisable: true,
                events: {
                  initialized() {
                    const editor: any = this;
                    editor.edit.off();
                  },
                },
              }}
            />
          ) : (
            <div />
          )}
        </div>

        <div
          className={clsx(styles.item__headerRight, {
            [styles.item__headerRightChecked]: progressItem?.isChecked,
          })}
        >
          {progressItem.checkbox && (
            <Checkbox
              value={progressItem?.isChecked}
              onClick={(e) => {
                e.stopPropagation();
                toggleCheckbox();
              }}
            />
          )}
          {progressItem?.isChecked && !!progressItem.checkedTimestamp && (
            <p className={styles.item__headerRightDate}>
              {dayjs(progressItem.checkedTimestamp).format("hh:mm:ss A")}
            </p>
          )}
        </div>
      </div>

      <div
        className={clsx({
          [styles.hide]:
            progressItem.collapsable && progressItem.startCollapsable,
        })}
      >
        {item.type === ChecklistItemType.Stopwatch && (
          <Stopwatch
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.Timer && (
          <Timer
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.Calculator && (
          <Calculator
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.DropdownSelector && (
          <Selector
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.MedCheck && (
          <MedCheck
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.Counter && (
          <Counter
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.InputText && (
          <InputText
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.TextBox && (
          <TextBox item={parsedItem} isDisable={isDisable} />
        )}

        {item.type === ChecklistItemType.Metronome && (
          <Metronome
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.TimeMarker && (
          <TimeMarker
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.Algorithm && (
          <Algorithm
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.BurnedAreas && (
          <BurnedAreas
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}

        {item.type === ChecklistItemType.TableData && (
          <DropdownData
            item={parsedItem}
            isDisable={isDisable}
            progressItem={progressItem}
          />
        )}
      </div>

      {progressItem.collapsable && progressItem.startCollapsable && (
        <div className={styles.item__underline} />
      )}
    </div>
  );
};
