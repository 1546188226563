import { GET_CHOOSED_ORGANIZATIOM, alertVar } from "~localCache";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_NEW_PROGRESS_BY_CHECKLIST,
  IMPORT_CHECKEM_DRAFT,
} from "~mutations";
import { IDraft } from "~models";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_ALL_ACTIVE_PROGRESSES } from "~queries";

interface IProps {
  checklist: IDraft;
}

export const useChecklist = ({ checklist }: IProps) => {
  const navigate = useNavigate();
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);
  const [createNewProgressByChecklist] = useMutation(
    CREATE_NEW_PROGRESS_BY_CHECKLIST
  );
  const [importDraft] = useMutation(IMPORT_CHECKEM_DRAFT);

  const [showLoader, setShowLoader] = useState(false);

  const handleStart = () => {
    setShowLoader(true);
    createNewProgressByChecklist({ variables: { id: checklist.id } }).then(
      (resp) => {
        navigate(
          `/checklist/${checklist.id}?progressId=${resp.data.createNewProgressByChecklist.id}`
        );
        setShowLoader(false);
      }
    );
  };

  const handleCopyLink = async () => {
    try {
      setShowLoader(true);
      const { data } = await createNewProgressByChecklist({ variables: { id: checklist.id } });
      const link = `/checklist/${checklist.id}?generateProgress`;
      return link;
    } catch (error) {
      console.error('Error creating progress:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleImportButton = () => {
    setShowLoader(true);
    importDraft({
      variables: {
        input: {
          organizationId: choosedOrganization.id,
          checklistId: checklist.id,
        },
      },
    }).then(() => {
      alertVar({
        text: "The checklist has been successfully imported",
        type: "green",
      });
      setShowLoader(false);
    });
  };

  return { showLoader, handleStart, handleImportButton, handleCopyLink };
};
