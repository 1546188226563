import {
  CheckSquareBroken,
  EmptyPlay,
  CopyToClipboard as CopyToClipboardIcon
} from '~icons';
import { useChecklist } from '../../useChecklist';
import { Button } from '~components';
import { IDraft } from '~models';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './styles.module.scss';
import { alertVar } from '~localCache';
import { Tooltip } from 'react-tooltip';

interface IProps {
  checklist: IDraft;
}

export const ChecklistItem = ({ checklist }: IProps) => {
  const { showLoader, handleStart, handleCopyLink } = useChecklist({ checklist });

  return (
    <>
      <div className={styles.activeChecklist}>
        <div className={styles.activeChecklist__name}>
          <CheckSquareBroken stroke='#29B473' />
          {checklist.title}
        </div>

        <div>
          <Button
            loading={showLoader}
            onClick={handleStart}
            text='Start'
            backgroundType='green'
            icon={<EmptyPlay stroke='white' />}
          />
        </div>

        <div className={styles.copyIconWrapper}>
          <CopyToClipboard
            text=''
            onCopy={async event => {
              const link = await handleCopyLink();
              if (link) {
                navigator.clipboard.writeText(
                  `${window.location.origin}${link}`
                );
                alertVar({ text: 'Link copied successfully', type: 'green' }); 
              }
            }}
          >
            <div data-tooltip-id='copy-tooltip'>
              <CopyToClipboardIcon stroke='#fff' fill='black' />
            </div>
          </CopyToClipboard>
          <Tooltip id='copy-tooltip' content='Copy link' />
        </div>
      </div>

      <div className={styles.activeChecklist__underline} />
      <ToastContainer />
    </>
  );
};
