import { IItem } from "~models";
import { useChecklist } from "../../../../context";
import { evaluate } from "mathjs";
import { useState } from "react";

interface IProps {
  item: IItem;
  progressItem: any;
}

export const useCalculator = ({ item, progressItem }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const itemWithParsedData =
    typeof item.data === "string"
      ? { ...item, data: JSON.parse(item.data) }
      : item;
  const isNoData =
    itemWithParsedData.data.inputs.findIndex(
      (item: { name: string }) => !!item.name
    ) === -1;

  const handleInputChange = (value: string, index: number) => {
    // if ((!/^\d*\.?\d*$/.test(value) && value !== "") || value[0] === ".")
    //   return;
    if (!/^[\d*/]*$/.test(value) && value !== "") return;

    setProgress((prev) => {
      let state = { ...prev };
      if (!state.data.items[itemIndex]?.inputs) {
        state.data.items[itemIndex].inputs = item.data.inputs.map(() => "");
      }
      state.data.items[itemIndex].inputs[index] = value;
      return state;
    });
  };

  const checkLogicRange = (value: number, range: any) => {
    if (value >= parseFloat(range.from) && value <= parseFloat(range.to)) {
      return range.logic;
    }
    return null;
  };

const calculate = () => {
  let resOutputs: { name: string; result: string | number }[] = [];
  const alphabet = ['a', 'b', 'c', 'd', 'e'];
  const outputs = item.data.outputs;
  const inputs = progressItem.inputs;

  outputs.forEach(
    (
      output: {
        logic: string;
        name: string;
        logicRanges: {
          from: string;
          to: string;
          logic: string;
          makeTextbox?: boolean;
        }[];
        maxAllowableOutput: boolean;
        maxOutputValue: string;
      },
      index: number
    ) => {

      let exp = output.logic;
      let logicRanges = item.data?.logicRanges && item.data?.logicRanges[index];
      let logic = logicRanges && checkLogicRange(inputs[index], logicRanges);

      if (logic && logicRanges && logicRanges.makeTextbox) {
        resOutputs.push({ name: output.name, result: logicRanges.logic });
      } else {
        let expression = logic ? logic : exp;

        // Build a map of variable names to their values
        let variableMap: { [key: string]: number } = {};

        // Map 'a', 'b', etc., to user inputs
        alphabet.forEach((letter, idx) => {
          variableMap[letter] = inputs[idx];
        });

        // Map 'o1', 'o2', etc., to previous outputs
        resOutputs.forEach((prevOutput, idx) => {
          const outputVarName = `o${idx + 1}`;
          variableMap[outputVarName] = Number(prevOutput.result);
        });

        // Replace variables in the expression
        let replacedExpression = expression.replace(
          /[a-e]|o\d+/g,
          (match: string) => {
            if (variableMap.hasOwnProperty(match)) {
              return variableMap[match];
            } else {
              console.warn(`Variable ${match} not found. Setting to 0.`);
              return '0';
            }
          }
        );

        try {
          // Evaluate the expression
          let result = Number(evaluate(replacedExpression).toFixed(3));

          // Apply maximum output constraint if necessary
          if (
            output.maxAllowableOutput &&
            result >= parseFloat(output.maxOutputValue)
          ) {
            result = parseFloat(output.maxOutputValue);
          }

          resOutputs.push({ name: output.name, result });
        } catch (error) {
          console.error(
            `Error evaluating expression: ${replacedExpression}`,
            error
          );
          resOutputs.push({ name: output.name, result: 'Error' });
        }
      }
    }
  );

  setProgress(prev => {
    let state = { ...prev };
    state.data.items[itemIndex].outputs = resOutputs;
    return state;
  });
};


  const areInputsValid =
    progressItem?.inputs &&
    progressItem.inputs.length > 0 &&
    progressItem.inputs.every((input: string) => {
      // Input should match the allowed pattern

      const isValidPattern = /^[\d*/]+$/.test(input);
      if (!isValidPattern) return false;

      try {
        const value = evaluate(input);
        return !isNaN(value);
      } catch (error) {
        return false;
      }
    });

  const disableCalculateButton = item.dropdownInputs ? !item.dropdownInputs : !areInputsValid;

  return {
    isNoData,
    itemWithParsedData,
    disableCalculateButton,
    handleInputChange,
    calculate,
  };
};
