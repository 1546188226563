import { ChecklistItemType, PublishingStatus, IDraft, ISection } from "~models";

export const defaultSection: ISection = {
  id: new Date().getTime(),
  name: null,
  description: "",
  collapsable: false,
  startCollapsable: true,
  dropdownInputs: false,
  checkbox: false,
  color: "#29B473",
  checklistTabId: 1,
  items: [],
  order: 1,
};

export const testChecklist: IDraft = {
  id: 1,
  title: "test",
  organizationId: 2,
  status: PublishingStatus.Published,
  tabs: [
    {
      id: new Date().getTime(),
      name: "Tab 1",
      checklistId: 2,
      order: 1,
      sections: [{ ...defaultSection }],
    },
  ],
  pdfUrl: null,
  pdfName: null,
  type: "Draft",
};

export const defaultTab: ITab = {
  id: new Date().getTime(),
  name: "Tab 1",
  checklistId: 2,
  order: 1,
  sections: [
    {
      id: new Date().getTime(),
      name: null,
      description: "",
      collapsable: false,
      startCollapsable: true,
      dropdownInputs: false,
      checkbox: false,
      color: "#29B473",
      checklistTabId: 1,
      items: [],
      order: 1,
    },
  ],
};

export interface IList {
  id: number;
  title: string;
  organizationId: number;
  tabs: ITab[];
}

export interface ITab {
  id: number;
  name: string;
  checklistId: number;
  order: number;
  sections: ISection[];
}

export interface IActiveItem {
  type: "section" | "item";
  id: number;
  sectionIndex: number;
  sectionId: number;
}

export interface IItem {
  id: number;
  type: null | ChecklistItemType;
  data: null | any;
  tabSectionId: number;
  order: number;
  description: null | string;
  checkbox: boolean;
}

export const defaultItem: IItem = {
  id: 1,
  type: null,
  data: null,
  tabSectionId: 1,
  order: 1,
  description: null,
  checkbox: false,
};

export interface IStopwatchData {
  text: string;
  sound: string;
  minutes: number;
  seconds: number;
  ringMinutes: number;
  ringSeconds: number;
  repeat: boolean;
  textToSpeech?: string;
}
