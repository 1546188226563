import React, { useState } from 'react';
import styles from './styles.module.scss';

import { IItem } from '../../../../../constants';
import { useCalculator } from './useCalculator';
import { Button, Input, Dropdown } from '~components';

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const Calculator = ({ item, isDisable }: IProps) => {
  const { isNoData, itemWithParsedData } = useCalculator({ item });

  const { inputs } = itemWithParsedData.data;

  // State to keep track of selected input index and value
  const [selectedInputIndex, setSelectedInputIndex] = useState<number | null>(
    null
  );
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <div className={styles.calculator}>
      {isNoData ? (
        <div className={styles.calculator__noData}>No data</div>
      ) : (
        <>
          {item.dropdownInputs ? (
            <>
              <div className={styles.calculator__field}>
                <Dropdown
                  placeholder='Select input'
                  value={
                    selectedInputIndex !== null
                      ? inputs[selectedInputIndex].name
                      : ''
                  }
                  data={inputs.map(
                    (input: { name: string; value: string }) => input.name
                  )}
                  onChange={(index: number, value: string) => {
                    setSelectedInputIndex(index);
                    setInputValue(inputs[index].value || '');
                  }}
                />
              </div>
            </>
          ) : (
            inputs.map((input: { name: string }, index: number) => {
              return !!input.name ? (
                <div
                  key={`${input.name} - ${index}`}
                  className={styles.calculator__field}
                >
                  <Input
                    disable={isDisable}
                    label={input.name}
                    placeholder='Enter value'
                  />
                </div>
              ) : null;
            })
          )}

          <Button
            disable={isDisable}
            backgroundType={"green"}
            text="Calculate"
          />

          {/* {item.data.outputs.map((item: { name: string; logic: string }) => {
            return !!item.name ? (
              <div className={styles.calculator__field}>
                <Input label={item.name} placeholder="Enter value" />
              </div>
            ) : null;
          })} */}
        </>
      )}
    </div>
  );
};
