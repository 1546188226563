import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

import { useCalculator } from './useCalculator';
import { Button, Dropdown, Input } from '~components';
import { IItem } from '~models';

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const Calculator = ({ item, isDisable, progressItem }: IProps) => {
  const {
    isNoData,
    itemWithParsedData,
    disableCalculateButton,
    calculate,
    handleInputChange,
  } = useCalculator({ item, progressItem });

  const { inputs } = itemWithParsedData.data;

  const [selectedInputIndex, setSelectedInputIndex] = useState<number>(0);
  const [showOutput, setShowOutput] = useState<boolean>(false);

  useEffect(() => {
    if (!itemWithParsedData.dropdownInputs || !inputs[0]?.value) {
      return;
    }
    inputs.map((val: any, index: number) => {
      handleInputChange(inputs[index].value, index)
      if (inputs[index]?.makeDefault == true)
        setSelectedInputIndex(index)
  })
  }, []);

  const handleCalculate = () => {
    calculate();
    setShowOutput(true);
  }

  return (
    <div className={styles.calculator}>
      {isNoData ? (
        <div className={styles.calculator__noData}>No data</div>
      ) : (
        <>
          {itemWithParsedData.dropdownInputs ? (
            <div className={styles.calculator__field}>
              <Dropdown
                placeholder='Select input'
                value={
                  selectedInputIndex !== null
                    ? inputs[selectedInputIndex].name
                    : inputs[0].name
                }
                onChange={index => {
                  setSelectedInputIndex(index);
                  handleInputChange(
                    inputs[index].value,
                    index
                  );
                  setShowOutput(false)
                }}
                data={inputs.map(
                  (input: { name: string; value: string }) => input.name
                )}
              />
            </div>
          ) : (
            inputs.map((item: { name: string }, index: number) =>
              !!item.name ? (
                <div
                  key={`${index} - calculator `}
                  className={styles.calculator__field}
                  onClick={e => e.stopPropagation()}
                >
                  <Input
                    value={
                      !!progressItem.inputs ? progressItem.inputs[index] : ''
                    }
                    onChange={value => handleInputChange(value, index)}
                    disable={isDisable}
                    label={item.name}
                    placeholder='Enter value'
                  />
                </div>
              ) : null
            )
          )}

          <Button
            onClick={handleCalculate}
            disable={isDisable || disableCalculateButton}
            backgroundType={'green'}
            text='Calculate'
          />

          {showOutput && !!progressItem?.outputs &&
            progressItem.outputs.map(
              (item: { name: string; result: number }, index: number) =>
                index === selectedInputIndex ||
                !itemWithParsedData.dropdownInputs ? (
                  <div
                    key={`output - ${index}`}
                    className={styles.calculator__output}
                  >
                    <p className={styles.calculator__outputName}>{item.name}</p>
                    <p className={styles.calculator__outputResult}>
                      {item.result}
                    </p>
                  </div>
                ) : null
            )}

          {/* {item.data.outputs.map((item: { name: string; logic: string }) => {
            return !!item.name ? (
              <div className={styles.calculator__field}>
                <Input label={item.name} placeholder="Enter value" />
              </div>
            ) : null;
          })} */}
        </>
      )}
    </div>
  );
};
