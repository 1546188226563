const alphabet: string[] = ["a", "b", "c", "d", "e"];
const validСharacters: string[] = ["*", "/", "-", "+"];
const scopes: string[] = ["(", ")"];
const validNumbers: string[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
];

const isInvalidCharacters = (arr: string[], allowedValues: string[]) => {
  return arr.findIndex((val) => !allowedValues.includes(val)) > -1;
};

const isPresentMisplacedPeriod = (arr: string[]) => {
  const isTwoDotsInsideOneNumber = (index: number) => {
    let newArr = [...arr];
    let itemsBeforeDot = newArr.splice(0, index).reverse();

    for (let i = 0; i < itemsBeforeDot.length; i++) {
      if (
        !validNumbers.includes(itemsBeforeDot[i]) &&
        itemsBeforeDot[i] === "."
      ) {
        return true;
      } else if (
        !validNumbers.includes(itemsBeforeDot[i]) &&
        itemsBeforeDot[i] !== "."
      ) {
        return false;
      }
    }

    return false;
  };

  return (
    arr.findIndex((item, index) => {
      if (item === ".") {
        if (index === 0) return false;
        if (
          alphabet.includes(arr[index - 1]) ||
          scopes.includes(arr[index - 1])
        )
          return true;
        if (isTwoDotsInsideOneNumber(index)) return true;
      }
    }) > -1
  );
};

const characterAfterDot = (arr: string[]) => {
  return (
    arr.findIndex((item, index) => {
      if (validСharacters.includes(item) && arr[index - 1] === ".") return true;
    }) > -1
  );
};

const scopeAfterDot = (arr: string[]) => {
  return (
    arr.findIndex((item, index) => {
      if (scopes.includes(item) && arr[index - 1] === ".") return true;
    }) > -1
  );
};

const letterAfterDot = (arr: string[], allowedLetters: string[]) => {
  return (
    arr.findIndex((item, index) => {
      if (allowedLetters.includes(item) && arr[index - 1] === ".") return true;
    }) > -1
  );
};

const twoCharactersTogether = (arr: string[]) => {
  return (
    arr.findIndex((item1, index) => {
      if (
        validСharacters.includes(item1) &&
        validСharacters.includes(arr[index + 1])
      )
        return true;
    }) > -1
  );
};

const twoLettersTogether = (arr: string[], validLetters: string[]) => {
  return (
    arr.findIndex((item1, index) => {
      if (validLetters.includes(item1) && validLetters.includes(arr[index + 1]))
        return true;
    }) > -1
  );
};

const letterAndNumberTogether = (
  arr: string[],
  validLetters: string[],
  validNumbers: string[]
) => {
  return (
    arr.findIndex((item1, index) => {
      if (validLetters.includes(item1) && validNumbers.includes(arr[index + 1]))
        return true;
    }) > -1
  );
};

const numberAndLetterTogether = (
  arr: string[],
  validLetters: string[],
  validNumbers: string[]
) => {
  return (
    arr.findIndex((item1, index) => {
      if (validNumbers.includes(item1) && validLetters.includes(arr[index + 1]))
        return true;
    }) > -1
  );
};

const isEmptyScopes = (val: string) => {
  return val.includes("()") || val.includes(")(");
};

const characterBeforeCloseScope = (arr: string[]) => {
  return (
    arr.findIndex((item, index) => {
      if (item === scopes[1] && validСharacters.includes(arr[index - 1])) {
        return true;
      }
    }) > -1
  );
};

const eqyalNumberOfScopes = (arr: string[]) => {
  return (
    arr.filter((item) => item === scopes[1]).length >
    arr.filter((item) => item === scopes[0]).length
  );
};

const numberOrLetterBeforeOpenScope = (
  arr: string[],
  validLetters: string[]
) => {
  return (
    arr.findIndex((item, index) => {
      if (item === scopes[0]) {
        if (
          validNumbers.includes(arr[index - 1]) ||
          validLetters.includes(arr[index - 1])
        )
          return true;
      }
    }) > -1
  );
};

const numberOrLetterAfterCloseScope = (
  arr: string[],
  validLetters: string[]
) => {
  return (
    arr.findIndex((item, index) => {
      if (item === scopes[1]) {
        if (
          validNumbers.includes(arr[index + 1]) ||
          validLetters.includes(arr[index + 1])
        )
          return true;
      }
    }) > -1
  );
};

export const checkIsExpressionValid = (
  val: string,
  itemsLength: number,
  outputs: Array<{ outputAsInput: boolean }>
) => {
  const arrFromValue = val.split("");
  const validLetters = alphabet.slice(0, itemsLength);
  const validOutputs = outputs
    .map((output, index) => (output.outputAsInput ? `o${index + 1}` : null))
    .filter(Boolean) as string[];

  const allowedValues = [
    ...validLetters,
    ...validOutputs,
    ...validСharacters,
    ...validNumbers,
    ...scopes,
    ".",
    "o",
  ];

  if (isInvalidCharacters(arrFromValue, allowedValues)) return false;

  if (isPresentMisplacedPeriod(arrFromValue)) return false;

  if (characterAfterDot(arrFromValue)) return false;

  if (scopeAfterDot(arrFromValue)) return false;

  if (letterAfterDot(arrFromValue, validLetters)) return false;

  if (val.length > 0 && [...validСharacters, scopes[1]].includes(val[0]))
    return false;

  if (val.length > 2 && twoCharactersTogether(arrFromValue)) return false;

  if (val.length > 0) {
    if (twoLettersTogether(arrFromValue, validLetters)) return false;

    if (letterAndNumberTogether(arrFromValue, validLetters, validNumbers))
      return false;

    if (numberAndLetterTogether(arrFromValue, validLetters, validNumbers))
      return false;
  }

  if (isEmptyScopes(val)) return false;
  if (characterBeforeCloseScope(arrFromValue)) return false;
  if (eqyalNumberOfScopes(arrFromValue)) return false;
  if (numberOrLetterBeforeOpenScope(arrFromValue, validLetters)) return false;

  if (numberOrLetterAfterCloseScope(arrFromValue, validLetters)) return false;

  return true;
};
