import { PublishingStatus } from "./ICommon";

export enum DraftStatus {
  Unpublished = "Unpublished",
  Published = "Published",
  UnpublishedChanges = "UnpublishedChanges",
}

export interface IDraft {
  id: number;
  title?: string;
  status: PublishingStatus;
  organizationId: number;
  lastModified?: string;
  tabs: ITab[];
  pdfUrl: null | string;
  pdfName: null | string;
  type: string;
  isPdfDeleted?: boolean;
  firstName?: string;
  lastName?: string;
  folderId?: number;
  createdAt?: number;
}

export interface IActiveChecklist {
  id: number;
  completed: boolean;
  data: string;
  organizationId: number;
  checklistId: number;
  userId: number;
  createdAt: string;
  updatedAt: string;

  checklist: {
    id: number;
    title: string;
    version: number;
    latest: boolean;
    organizationId: number;
    draftId: number;
  };
  user?: {
    firstName?: string;
    lastName?: string;
    avatar?: string;
  };
}

export interface ITab {
  id: number;
  name: string;
  checklistId: number;
  order: number;
  sections: ISection[];
}

export interface ISection {
  id: number;
  name: null | string;
  description: null | string;
  collapsable: boolean;
  startCollapsable: boolean;
  checkbox: boolean;
  color: string;
  checklistTabId: number;
  order: number;
  items: IItem[];
  dropdownInputs: boolean;
}

export interface IItem {
  id: number;
  type: null | ChecklistItemType;
  data: null | any;
  tabSectionId: number;
  order: number;
  description: null | string;
  checkbox: boolean;
  collapsable: boolean;
  startCollapsable: boolean;
  dropdownInputs: boolean;
}

export enum ChecklistItemType {
  TextBox = "TextBox",
  Timer = "Timer",
  Metronome = "Metronome",
  Stopwatch = "Stopwatch",
  Calculator = "Calculator",
  MedCheck = "MedCheck",
  InputText = "InputText",
  Counter = "Counter",
  DropdownSelector = "DropdownSelector",
  TimeMarker = "TimeMarker",
  Checkbox = "Checkbox",
  Algorithm = "Algorithm",
  BurnedAreas = "BurnedAreas",
  TableData = "TableData",
}
