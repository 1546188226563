import { useBuilder } from "../../../../context";

export const useDefaultSettings = () => {
  const { setList, activeTabIndex, list, activeItem } = useBuilder();

  const itemIndex = !!activeItem
    ? list.tabs[activeTabIndex].sections[
        activeItem?.sectionIndex
      ]?.items.findIndex((elem) => elem.id === activeItem.id)
    : -1;

  let item =
    !!activeItem && itemIndex > -1
      ? structuredClone(
          list.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
            itemIndex
          ]
        )
      : null;

  if (!!item && typeof item.data === "string") {
    item.data = JSON.parse(item.data);
  }

  const toggleDescription = () => {
    if (!activeItem) return;
    if (itemIndex === -1) return;
    let newValue = item?.description === null ? "" : null;

    let newItem = { ...item };
    newItem.description = newValue;
    if (typeof newItem.data === "string")
      newItem.data = JSON.parse(newItem.data);

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ] = newItem;
      return state;
    });
  };

  const toggleCheckbox = () => {
    if (itemIndex === -1) return;

    let newItem = { ...item };
    newItem.checkbox = !item.checkbox;
    if (typeof newItem.data === "string")
      newItem.data = JSON.parse(newItem.data);

    setList((prev) => {
      let state = { ...prev };
      if (!activeItem) return state;
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ] = newItem;
      return state;
    });
  };

  const toggleCollapsable = () => {
    if (!activeItem) return;

    let newItem = { ...item };
    newItem.collapsable = !item.collapsable;
    if (typeof newItem.data === "string")
      newItem.data = JSON.parse(newItem.data);

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ] = newItem;
      return state;
    });
  };

  const toggleStartCollapsable = () => {
    if (!activeItem) return;

    let newItem = { ...item };
    newItem.startCollapsable = !item.startCollapsable;
    if (typeof newItem.data === "string")
      newItem.data = JSON.parse(newItem.data);

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ] = newItem;
      return state;
    });
  };

  const toggleDropdownInputs = () => {
    if (!activeItem) return;

    let newItem = { ...item };
    newItem.dropdownInputs = !item.dropdownInputs;
    if (typeof newItem.data === 'string')
      newItem.data = JSON.parse(newItem.data);

    setList(prev => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ] = newItem;
      return state;
    });
  };

  return {
    toggleDescription,
    toggleCheckbox,
    item,
    itemIndex,
    toggleCollapsable,
    toggleStartCollapsable,
    toggleDropdownInputs,
  };
};
