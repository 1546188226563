import { alertVar } from "~localCache";
import { IItem } from "../../../../constants";
import { useBuilder } from "../../../../context";
import { checkIsExpressionValid } from "./helper";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useCalculator = ({ item, itemIndex }: IProps) => {
  const { setList, activeItem, activeTabIndex } = useBuilder();
  const alphabet = ["a", "b", "c", "d", "e"];

  let data =
    typeof item.data === "string"
      ? structuredClone(JSON.parse(item.data))
      : structuredClone(item.data);

  const handleAddInput = () => {
    if (item.data.inputs.length === 5) {
      alertVar({
        type: 'red',
        text: 'You have used the maximum number of inputs',
      });
    } else {
      if (!activeItem) return;
      data.inputs.push({ name: '', value: '' });
      setList((prev) => {
        let state = { ...prev };
        state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
          itemIndex
        ].data = data;
        return state;
      });
    }
  };

  const handleDeleteInput = (inputIndex: number) => {
    if (!activeItem) return;
    const outputsWithoutСurrentOperand = item.data.outputs.filter(
      (item: { logic: string }) => !item.logic.includes(alphabet[inputIndex])
    );
    data.inputs.splice(inputIndex, 1);
    data.outputs = outputsWithoutСurrentOperand;
    setList((prev) => {
      let state = { ...prev };

      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleChangeInput = (
    value: string | boolean,
    inputIndex: number,
    field: 'name' | 'value' | 'makeDefault'
  ) => {
    if (!activeItem) return;

    if (field === 'makeDefault' && value === true) {
      data.inputs = data.inputs.map((input: any, i: number) => ({
        ...input,
        makeDefault: i === inputIndex ? true : false
      }));
    } else {
      data.inputs[inputIndex][field] = value;
    }

    setList(prev => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };


  const handleAddOutput = () => {
    if (!activeItem) return;
    data.outputs.push({ name: "", logic: "" });
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDeleteOutput = (outputIndex: number) => {
    if (!activeItem) return;
    data.outputs.splice(outputIndex, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleChangeOutputName = (value: string, outputIndex: number) => {
    if (!activeItem) return;
    data.outputs[outputIndex].name = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleChangeOutputLogic = (value: string, outputIndex: number) => {
    if (
      !activeItem ||
      !checkIsExpressionValid(
        value,
        item.data.inputs.length,
        item.data.outputs,
      )
    ) {
      alertVar({
        type: "red",
        text: "Invalid expression"
      });
      return;
    }
    data.outputs[outputIndex].logic = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const disableAddInputButton = item.data.inputs.length === 5;

  const logicRanges = item.data.logicRanges || [];
  const disableAddLogicRangeButton = logicRanges.length === 5;

  const handleAddLogicRange = () => {
    if (!activeItem) return;

    if (!data.logicRanges) {
      data.logicRanges = [];
    }

    if (data.logicRanges.length >= 5) {
      alertVar({
        type: "red",
        text: "You have reached the maximum number of logic ranges",
      });
      return;
    }

    const newRange = { from: "", to: "", logic: "" };
    data.logicRanges.push(newRange);

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[itemIndex].data = data;
      return state;
    });
  };

  const handleChangeRangeValue = (value: string, rangeIndex: number, key: 'from' | 'to') => {
    if (!activeItem) return;

    data.logicRanges[rangeIndex][key] = value;

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[itemIndex].data = data;
      return state;
    });
  };

  const handleChangeRangeLogic = (value: string, rangeIndex: number) => {
    if (!activeItem) return;

    data.logicRanges[rangeIndex].logic = value;

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[itemIndex].data = data;
      return state;
    });
  };

  const handleDeleteLogicRange = (rangeIndex: number) => {
    if (!activeItem) return;

    data.logicRanges.splice(rangeIndex, 1);

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[itemIndex].data = data;
      return state;
    });
  };

  const handleMaxAllowableToggle = (outputIndex: number) => {
    if (!activeItem) return;
    data.outputs[outputIndex].maxAllowableOutput =
      !data.outputs[outputIndex].maxAllowableOutput;
    if (!data.outputs[outputIndex].maxAllowableOutput) {
        data.outputs[outputIndex].maxOutputValue = '';
    }
    setList(prev => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleMaxOutputValueChange = (value: string, outputIndex: number) => {
    if (
      !activeItem ||
      !checkIsExpressionValid(
        value,
        item.data.inputs.length,
        item.data.outputs,
      )
    ) {
      alertVar({
        type: "red",
        text: "Invalid expression"
      });
      return;
    }

    data.outputs[outputIndex].maxOutputValue = value;
    setList(prev => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleToggleTextboxOutput = (rangeIndex: number) => {
    if (!activeItem) return;
    data.logicRanges[rangeIndex].makeTextbox =
      !data.logicRanges[rangeIndex].makeTextbox;

    setList(prev => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleToggleOutputAsInput = (outputIndex: number) => {
    if (!activeItem) return;

    data.outputs[outputIndex].outputAsInput =
      !data.outputs[outputIndex].outputAsInput;

    setList(prev => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  return {
    alphabet,
    disableAddInputButton,
    handleAddInput,
    handleDeleteInput,
    handleChangeInput,
    handleAddOutput,
    handleDeleteOutput,
    handleChangeOutputName,
    handleChangeOutputLogic,
    handleAddLogicRange,
    handleChangeRangeValue,
    handleChangeRangeLogic,
    handleDeleteLogicRange,
    disableAddLogicRangeButton,
    handleMaxAllowableToggle,
    handleMaxOutputValueChange,
    handleToggleTextboxOutput,
    handleToggleOutputAsInput,
  };
};
