import { SwitchField } from "~components";
import styles from "./styles.module.scss";
import { useDefaultSettings } from "./useDefaultSettings";
import { ChecklistItemType } from "~models";

import { StopwatchSettings } from "../StopwatchSettings";
import { TimerSettings } from "../TimerSettings";
import { MetronomeSettings } from "../MetronomeSettings";
import { TextBoxSettings } from "../TextBoxSettings";
import { CalculatorSettings } from "../CalculatorSettings";
import { MedCheckSettings } from "../MedCheckSettings";
import { CounterSettings } from "../CounterSettings";
import { SelectorSettings } from "../SelectorSettings";
import { TimeMarkerSettings } from "../TimeMarkerSettings";
import { AlgorithmSettings } from "../AlgorithmSettings";
import { BurnedAreasSettings } from "../BurnedAreasSettings";
import { DropdownDataSettings } from "../DropdownDataSettings";

export const ItemSettings = () => {
  const {
    toggleDescription,
    toggleCheckbox,
    toggleCollapsable,
    toggleStartCollapsable,
    toggleDropdownInputs,
    item,
    itemIndex,
  } = useDefaultSettings();

  if (!item || itemIndex === -1) return null;
  return (
    <div className={styles.itemSettings}>
      <div className={styles.itemSettings__field}>
        <SwitchField
          text='Description'
          value={item.description !== null}
          onClick={toggleDescription}
        />
      </div>
      <div className={styles.itemSettings__field}>
        <SwitchField
          text='Checkbox'
          value={item.checkbox}
          onClick={toggleCheckbox}
        />
      </div>

      <div className={styles.itemSettings__field}>
        <SwitchField
          text='Make Collapsable'
          value={item.collapsable}
          onClick={toggleCollapsable}
        />
      </div>
      {item.collapsable && (
        <>
          <div className={styles.itemSettings__field}>
            <SwitchField
              text='Start as Collapsed'
              value={item.startCollapsable}
              onClick={toggleStartCollapsable}
            />
          </div>
        </>
      )}
      {item.type === ChecklistItemType.Calculator && (
        <div className={styles.itemSettings__field}>
          <SwitchField
            text='Add dropdown inputs'
            value={item.dropdownInputs}
            onClick={toggleDropdownInputs}
          />
        </div>
      )}

      {/* {!!item.type && item.type !== ChecklistItemType.Metronome && (
        <div className={styles.itemSettings__field}>
          <SwitchField
            text="Record time at beginning and end of stopwatch"
            value={false}
            onClick={() => {}}
          />
        </div>
      )} */}

      {/* <div className={styles.itemSettings__underline} /> */}

      {item.type === ChecklistItemType.Stopwatch && (
        <StopwatchSettings item={item} itemIndex={itemIndex} />
      )}
      {item.type === ChecklistItemType.Timer && (
        <TimerSettings item={item} itemIndex={itemIndex} />
      )}
      {item.type === ChecklistItemType.Metronome && (
        <MetronomeSettings item={item} itemIndex={itemIndex} />
      )}
      {item.type === ChecklistItemType.TextBox && (
        <TextBoxSettings item={item} itemIndex={itemIndex} />
      )}

      {item.type === ChecklistItemType.Calculator && (
        <CalculatorSettings item={item} itemIndex={itemIndex} />
      )}

      {item.type === ChecklistItemType.MedCheck && (
        <MedCheckSettings item={item} itemIndex={itemIndex} />
      )}

      {item.type === ChecklistItemType.Counter && (
        <CounterSettings item={item} itemIndex={itemIndex} />
      )}

      {item.type === ChecklistItemType.DropdownSelector && (
        <SelectorSettings item={item} itemIndex={itemIndex} />
      )}

      {item.type === ChecklistItemType.TimeMarker && (
        <TimeMarkerSettings item={item} itemIndex={itemIndex} />
      )}

      {item.type === ChecklistItemType.Algorithm && (
        <AlgorithmSettings item={item} itemIndex={itemIndex} />
      )}

      {item.type === ChecklistItemType.BurnedAreas && (
        <BurnedAreasSettings item={item} itemIndex={itemIndex} />
      )}

      {item.type === ChecklistItemType.TableData && (
        <DropdownDataSettings item={item} itemIndex={itemIndex} />
      )}
    </div>
  );
};
