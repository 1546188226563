import { IItem } from '../../../../constants';
import { Dropdown, Input, Checkbox } from '~components';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { useCalculator } from './useCalculator';
import { PlusSymbol, Trash } from '~icons';
import { useState } from 'react';

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const CalculatorSettings = ({ item, itemIndex }: IProps) => {
  const {
    alphabet,
    disableAddInputButton,
    disableAddLogicRangeButton,
    handleAddInput,
    handleDeleteInput,
    handleChangeInput,
    handleAddOutput,
    handleDeleteOutput,
    handleChangeOutputName,
    handleChangeOutputLogic,
    handleAddLogicRange,
    handleChangeRangeValue,
    handleChangeRangeLogic,
    handleDeleteLogicRange,
    handleMaxAllowableToggle,
    handleMaxOutputValueChange,
    handleToggleTextboxOutput,
    handleToggleOutputAsInput,
  } = useCalculator({ item, itemIndex });

  const [inputTypes] = useState([
    'Standard Input',
    'Weight',
    'Ideal body weight'
  ]);

    const [inputStates, setInputStates] = useState(
      item.data.inputs.map((input: any) => ({
        ...input,
        type: input.type || 'Standard Input',
        value: input.value || '',
        makeDefault: input.makeDefault || false

      }))
    );

  const handleDropdownChange = (selectedTypeValue: string, index: number) => {
    const updatedInputs = [...inputStates];
    updatedInputs[index].type = selectedTypeValue;

    const inputValue =
      selectedTypeValue === 'Weight' ||
      selectedTypeValue === 'Ideal body weight'
        ? selectedTypeValue
        : '';

    updatedInputs[index].name = inputValue;
    setInputStates(updatedInputs);

    handleChangeInput(inputValue, index, 'name');
  };

  const handleInputChange = (
    value: string | boolean,
    index: number,
    field: 'name' | 'value' | 'makeDefault'
  ) => {
    let updatedInputs = [...inputStates];

    if (field === 'makeDefault' && value === true) {
      updatedInputs = updatedInputs.map((input, i) => ({
        ...input,
        makeDefault: i === index ? true : false
      }));
    } else {
      updatedInputs[index][field] = value;
    }

    setInputStates(updatedInputs);
    handleChangeInput(value, index, field);
  };

  const handleDeleteInputState = (index: number) => {
    const updatedInputs = inputStates.filter(
      (_: any, i: number) => i !== index
    );
    setInputStates(updatedInputs);
    handleDeleteInput(index);
  };

  const handleAddNewInput = () => {
    const newInput = {
      name: '',
      type: 'Standard Input'
    };
    setInputStates([...inputStates, newInput]);
    handleAddInput();
  };

  return (
    <div className={styles.calculatorSettings}>
      <div className={styles.calculatorSettings__underlineTop} />

      {inputStates.map(
        (
          input: {
            name: string;
            type: string;
            value: string;
            makeDefault: boolean;
            allowStandardInput: boolean;
          },
          index: number
        ) => {
          return (
            <>
              {item.dropdownInputs ? (
                <>
                  <div
                    key={`calculator - input - ${index}`}
                    className={styles.calculatorSettings__input}
                  >
                    <p>{alphabet[index]}</p>
                    <Input
                      value={input.name}
                      onChange={value =>
                        handleInputChange(value, index, 'name')
                      }
                      placeholder='Enter input name...'
                    />
                    <p>=</p>
                    <Input
                      value={input.value}
                      onChange={value => {
                        const regex = /^\d*\.?\d*$/;
                        if (regex.test(value)) {
                          handleInputChange(value, index, 'value');
                        }
                      }}
                      placeholder='Enter input value...'
                    />
                    <div onClick={() => handleDeleteInputState(index)}>
                      <Trash stroke='#979DB1' />
                    </div>
                  </div>
                  <div className={styles.dropdownCheckboxContainer}>
                    <Checkbox
                      value={input.makeDefault || false}
                      onClick={() =>{
                        handleInputChange(
                          !input.makeDefault,
                          index,
                          'makeDefault'
                        )
                      }
                      }
                    />
                    <label>Make default</label>
                  </div>
                </>
              ) : (
                <>
                  <div
                    key={`calculator - input - ${index}`}
                    className={styles.calculatorSettings__input}
                  >
                    <p>{alphabet[index]}</p>
                    <Input
                      value={input.name}
                      onChange={value =>
                        handleInputChange(value, index, 'name')
                      }
                      placeholder='Enter input name...'
                      disable={
                        input.type === 'Weight' ||
                        input.type === 'Ideal body weight'
                      }
                    />
                    <div onClick={() => handleDeleteInputState(index)}>
                      <Trash stroke='#979DB1' />
                    </div>
                  </div>
                  <div className={styles.dropdownWrapper}>
                    <label className={styles.dropdownLabel}>Input Type</label>
                    <Dropdown
                      data={inputTypes}
                      value={input.type || 'Standard Input'}
                      onChange={(selectedTypeIndex, selectedTypeValue) =>
                        handleDropdownChange(selectedTypeValue, index)
                      }
                      placeholder='Select input type'
                    />
                  </div>
                </>
              )}
            </>
          );
        }
      )}

      <div
        onClick={handleAddNewInput}
        className={clsx(styles.calculatorSettings__addButton, {
          [styles.disable]: disableAddInputButton
        })}
      >
        <PlusSymbol fill={disableAddInputButton ? '#DBDEE7' : '#29B473'} />
        Add another input
      </div>

      <div className={styles.calculatorSettings__underline} />

      {/* Outputs Section */}
      {item.data.outputs.map(
        (
          output: {
            name: '';
            logic: '';
            maxAllowableOutput?: boolean;
            maxOutputValue?: string;
            outputAsInput?: boolean;
          },
          index: number
        ) => (
          <div key={`calculator - output - ${index}`}>
            <div className={styles.calculatorSettings__outputName}>
              <Input
                value={output.name}
                onChange={value => handleChangeOutputName(value, index)}
                label={`Output ${index + 1}${
                  output.outputAsInput ? ` (o${index + 1})` : ''
                }`}
                placeholder='Enter output name...'
              />
              <div onClick={() => handleDeleteOutput(index)}>
                <Trash stroke='#979DB1' />
              </div>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox
                value={output.outputAsInput || false}
                onClick={() => handleToggleOutputAsInput(index)}
              />
              <label>Make this output an input</label>
            </div>
            <div className={styles.calculatorSettings__output}>
              <Input
                value={output.logic}
                onChange={value => handleChangeOutputLogic(value, index)}
                label='Calculating logic'
                placeholder='Ex: a*50'
              />

              <p>=</p>
              <div>Output {index + 1}</div>
            </div>
            <div className={styles.maxOutputSection}>
              <div className={styles.inputContainer}>
                {output.maxAllowableOutput && (
                  <Input
                    value={output.maxOutputValue || ''}
                    onChange={value => handleMaxOutputValueChange(value, index)}
                    containerClassName={styles.customInputWidth}
                    label={
                      <>
                        Max allowable output <br /> for Output {index + 1} =
                      </>
                    }
                    placeholder='Enter max value...'
                  />
                )}
              </div>
              <div className={styles.checkboxContainer}>
                <Checkbox
                  value={output.maxAllowableOutput || false}
                  onClick={() => handleMaxAllowableToggle(index)}
                />
                <label>Create max allowable output</label>
              </div>
            </div>
          </div>
        )
      )}

      <div
        onClick={handleAddOutput}
        className={styles.calculatorSettings__addButton}
      >
        <PlusSymbol fill='#29B473' />
        Add another output
      </div>

      <div className={styles.calculatorSettings__underline} />

      {item.data.logicRanges &&
        item.data.logicRanges.map(
          (
            range: {
              from: string;
              to: string;
              logic: string;
              makeTextbox: boolean;
            },
            index: number
          ) => (
            <div
              key={`calculator-range-${index}`}
              className={styles.calculatorSettings__range}
            >
              <div className={styles.rangeWrapper}>
                <p className={styles.rangeLabel}>{alphabet[index]}</p>{' '}
                {/* Alphabet on top */}
                <div className={styles.inputWrapper}>
                  <Input
                    value={range.from}
                    onChange={value =>
                      handleChangeRangeValue(value, index, 'from')
                    }
                    placeholder='Ex: 10'
                  />
                  <span className={styles.toLabel}>to</span>
                  <Input
                    value={range.to}
                    onChange={value =>
                      handleChangeRangeValue(value, index, 'to')
                    }
                    placeholder='Ex: 20'
                  />
                  <div
                    onClick={() => handleDeleteLogicRange(index)}
                    className={styles.trashIcon}
                  >
                    <Trash stroke='#979DB1' />
                  </div>
                </div>
              </div>
              <div className={styles.calculatorSettings__output}>
                <Input
                  value={range.logic}
                  onChange={value => handleChangeRangeLogic(value, index)}
                  placeholder='Enter logic...'
                  label={`Calculating logic for (${alphabet[index]}) range`}
                />
                <p>=</p>
                <div>Output {index + 1}</div>
              </div>
              <div className={styles.rangesCheckboxContainer}>
                <Checkbox
                  value={range.makeTextbox || false}
                  onClick={() => handleToggleTextboxOutput(index)}
                />
                <label>Make this output a textbox</label>
              </div>
            </div>
          )
        )}

      <div
        onClick={handleAddLogicRange}
        className={clsx(styles.calculatorSettings__addButton, {
          [styles.disable]: disableAddLogicRangeButton
        })}
      >
        <PlusSymbol fill={disableAddLogicRangeButton ? '#DBDEE7' : '#29B473'} />
        Add logic range
      </div>
    </div>
  );
};
